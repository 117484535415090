<template>
     <div class="id_auth">
          <MemberTitle memberTitle="实名认证"></MemberTitle>

          <div v-if="!firstLoad">
               <div class="id_auth_container" v-if="stateNow=='start'">
                    <div class="auth_process">
                         <table>
                              <tr>
                                   <td>
                                        <ComponentA name="真实姓名" need="true"></ComponentA>
                                   </td>
                                   <td>
                                        <input type="text" class="class_input" placeholder="请输入真实姓名" v-model="form.name"
                                             maxlength="20">
                                   </td>
                              </tr>
                              <tr>
                                   <td>
                                        <ComponentA name="身份证号" need="true"></ComponentA>
                                   </td>
                                   <td>
                                        <input type="text" class="class_input" placeholder="请输入身份证号" v-model="form.id"
                                             maxlength="20">
                                   </td>
                              </tr>

                              <tr>
                                   <td></td>
                                   <td>
                                        <Button text="下一步" @click="stateClick"></Button>
                                   </td>
                              </tr>
                         </table>
                    </div>
               </div>
               <div class="result" v-if="stateNow=='none'||stateNow=='fail'">
                    <AuthResult></AuthResult>
               </div>

               <div class="auth_info" v-if="stateNow=='success'">
                    <p class="title">认证信息</p>
                    <div class="info_detail">
                         <table>
                              <tr>
                                   <td>
                                        <ComponentA name="真实姓名" need="false"></ComponentA>
                                   </td>
                                   <td>
                                        <span class="font333">{{accountInfo.personName}}</span>
                                   </td>
                              </tr>
                              <tr>
                                   <td>
                                        <ComponentA name="手机号" need="false"></ComponentA>
                                   </td>
                                   <td>
                                        <span class="font333">{{accountInfo.memberMobile}}</span>
                                   </td>
                              </tr>
                              <tr>
                                   <td>
                                        <ComponentA name="身份证号" need="false"></ComponentA>
                                   </td>
                                   <td>
                                        <span class="font333">{{accountInfo.personIds}}</span>
                                   </td>
                              </tr>

                         </table>
                    </div>
               </div>

          </div>


     </div>
</template>

<script>
     import { defineComponent, ref, reactive, getCurrentInstance, onMounted } from 'vue'
     import MemberTitle from '@/components/MemberTitle';
     import { ElMessage } from 'element-plus'
     import { useRoute } from 'vue-router'
     export default {
          components: {
               MemberTitle
          },
          setup() {
               const uploadImg = ref('')
               const { proxy } = getCurrentInstance()

               const form = reactive({
                    name: '',
                    id: ''
               })

               const accountInfo = ref({})
               const firstLoad = ref(true)
               const route = useRoute()
               const state = reactive({
                    none: {
                         img: require('@/assets/auth_avatar.png'),
                         imgStyle: { width: "118px", height: '118px' },
                         text: '尚未完成实名认证',
                         btnTxt: '立即认证',
                         tip: ''
                    },
                    fail: {
                         img: require('@/assets/auth_fail.png'),
                         imgStyle: { width: "222px", height: '160px' },
                         text: '实名认证审核失败',
                         tip: '失败原因：您填的信息不符合要求',
                         btnTxt: '重新认证'
                    },

                    // waiting: {
                    //      img: require('@/assets/auth_avatar.png'),
                    //      imgStyle: { with: "222px", height: '160px' },
                    //      text: '实名认证审核中',
                    //      tip: ''
                    // },

                    start: {
                         btnTxt: '下一步'
                    }
               })

               const stateNow = ref('none')


               const handleClick = () => {
                    console.log('handleClick')
               }

               const varifyAccount = () => {
                    proxy.$get('v3/member/front/openAccount/detail').then(res => {
                         if (res.state == 200) {
                              accountInfo.value = res.data
                              let { state, tlAccountState } = accountInfo.value
                              firstLoad.value = false
                              // switch (tlAccountState) {
                              //      case 0: {
                              //           stateNow.value = 'none'
                              //           break
                              //      }
                              //      case 11: {
                              //           stateNow.value = 'success'
                              //           break
                              //      }

                              //      case 12: {
                              //           stateNow.value = 'fail'
                              //           state['fail'].tip = `失败原因:${res.data.failReason}`
                              //           break
                              //      }
                              // }

                              if (tlAccountState == 12) {
                                   stateNow.value = 'fail'
                                   state['fail'].tip = `失败原因:${res.data.failReason}`
                                   return
                              }


                              if (state > 0) {
                                   stateNow.value = 'success'
                              } else if (state == 0) {
                                   if (route.query.from) {
                                        stateNow.value = 'start'
                                   } else {
                                        stateNow.value = 'none'

                                   }
                              }

                         }
                    })
               }


               const stateClick = () => {
                    if (stateNow.value == 'start') {
                         if (!form.name.trim()) {
                              ElMessage.warning('请输入真实姓名')
                              return
                         }

                         if (proxy.$checkIdentity(form.id.trim(), '') != true) {
                              ElMessage.warning(proxy.$checkIdentity(form.id.trim(), ''));
                              return
                         }

                         authorize()
                    }



                    if (stateNow.value == 'none' || stateNow.value == 'fail') stateNow.value = 'start'
               }


               const authorize = () => {
                    proxy.$post('v3/member/front/openAccount/personStep1', {
                         legalIds: form.id.trim(),
                         name: form.name.trim()
                    }).then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                              setTimeout(() => {
                                   stateNow.value = 'success'
                                   varifyAccount()
                              }, 1000)
                         } else {
                              stateNow.value = 'fail'
                         }
                    })
               }


               onMounted(() => {
                    varifyAccount()
               })


               const ComponentA = (props, context) => {
                    return (
                         <div className="leftName flex_row_end_center">
                              {props.need == 'true' ? <span className="red_dot">*</span> : ''}
                              <div className="font333">{props.name}：</div>
                         </div>

                    )
               }

               const Button = (props, context) => {
                    return (
                         <div className="button_primary">{props.text}</div>
                    )
               }

               const UploadBg = (props, context) => {
                    if (stateNow.value == 'success') {
                         return (
                              <div className="info_img"></div>
                         )
                    } else {
                         return (
                              <div className="upload_bg">
                                   {
                                        uploadImg.value ?
                                             <div className="uploaded">
                                                  <img src={require('@/assets/order/close.png')} onClick={handleClick} />
                                             </div> :
                                             <div className="upload_be">
                                                  <label for={props.id} className="upload_be flex_column_center_center">
                                                       <img src={require("@/assets/camera_shot.png")} alt="" />
                                                       <p>上传身份证人像页</p>
                                                  </label>
                                                  <input type="file" id={props.id} onChange={getFile} accept="image/*" />
                                             </div>
                                   }
                              </div>
                         )
                    }

               }





               const AuthResult = (props, context) => {
                    let stateNow1 = state[stateNow.value]
                    return (
                         <div style="width:100%;" className="res_state flex_column_center_center">
                              <img src={stateNow1.img} style={stateNow1.imgStyle} />
                              <p>{stateNow1.text}</p>
                              <span>{stateNow1.tip}</span>
                              {
                                   (stateNow1.btnTxt)
                                   && <Button text={stateNow1.btnTxt} onClick={stateClick}></Button>
                              }
                         </div>
                    )
               }

               return {
                    ComponentA,
                    Button,
                    handleClick,
                    UploadBg,
                    AuthResult,
                    state,
                    form,
                    stateNow,
                    firstLoad,
                    accountInfo,
                    stateClick
               }

          },
     }
</script>


<style lang="scss">
     .id_auth {
          width: 1020px;
          float: left;
          margin-left: 10px;

          .id_auth_container {
               background: #fff;
               padding: 15px;
               padding-left: 229px;
               padding-top: 68px;


          }

          .font333 {
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #121212;
          }


          .leftName {
               text-align: right;

               .red_dot {
                    color: $colorMain;
                    font-size: 16px;
                    margin-right: 5px;
               }
          }

          table {
               border-collapse: separate;
               border-spacing: 31px;

               .class_input {
                    width: 360px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #DDDDDD;
                    border-radius: 2px;
                    padding: 0 10px;
                    font-size: 15px;
               }

               .class_input1 {
                    width: 230px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #DDDDDD;
                    border-radius: 2px;
                    padding: 0 10px;
                    font-size: 15px;
               }

               .sms_code {
                    width: 120px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #DDDDDD;
                    border-radius: 2px;
                    text-align: center;
                    line-height: 40px;
                    margin-left: 10px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    cursor: pointer;
               }
          }

          .button_primary {
               width: 150px;
               height: 40px;
               background: #E73439;
               border-radius: 4px;
               text-align: center;
               font-size: 18px;
               font-family: Microsoft YaHei;
               font-weight: bold;
               color: #FFFFFF;
               line-height: 40px;
               cursor: pointer;
          }


          .upload_bg {
               width: 215px;
               height: 136px;
               border: 1px dashed #BFBFBF;
               border-radius: 2px;

               .upload_be {
                    width: 100%;
                    height: 100%;

                    img {
                         width: 45px;
                         height: 45px;
                    }

                    p {
                         margin-top: 15px;
                         font-size: 14px;
                         font-family: Microsoft YaHei;
                         font-weight: 400;
                         color: #BBBBBB;
                    }

                    #upload1,
                    #upload2 {
                         display: none;
                    }
               }

               .uploaded {
                    position: relative;

                    img {
                         position: absolute;
                         width: 16px;
                         height: 16px;
                         top: -10px;
                         right: -10px;
                    }
               }
          }

          .info_img {
               width: 260px;
               height: 165px;
               background: #EEEEEE;
               border-radius: 6px;
               background-position: center center;
               background-repeat: no-repeat;
               background-size: contain;
          }




          .result {
               background: #fff;
               height: 970px;

               .res_state {
                    padding-top: 100px;

                    p {
                         margin-top: 31px;
                         font-size: 16px;
                         font-family: Microsoft YaHei;
                         font-weight: 400;
                         color: #333333;
                    }

                    span {
                         margin-top: 16px;
                         font-size: 14px;
                         font-family: Microsoft YaHei;
                         font-weight: 400;
                         color: #999999;
                         margin-bottom: 31px;
                    }
               }
          }


          .auth_info {
               padding: 20px;
               background: #fff;

               .title {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: 40px;
               }
          }
     }
</style>